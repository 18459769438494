function isValidHttpUrl(string) {
    var res = string.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
    return (res !== null)
}

document.addEventListener("DOMContentLoaded", (e) => {
    let checkboxActive = document.querySelectorAll(".js-checkbox-status");

    checkboxActive.forEach(checkbox => {
        checkbox.addEventListener('change', function() {
            info = this.closest('.notification-modal').querySelector('.modal-form');

            if(this.checked) {
                info.classList.remove('hidden')
            } else {
                info.classList.add('hidden')
            }
        });
    })

    let webhookUrl = document.getElementById('webhook_url');

    if(webhookUrl){
        webhookUrl.addEventListener('keyup', function() {
            if(!isValidHttpUrl(this.value)){
                this.classList.remove('bg-green-100');
                this.classList.add('bg-red-200');
            } else {
                this.classList.remove('bg-red-200');
                this.classList.add('bg-green-100');
            }
        })
    }
});